const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['languages'] = require('../middleware/languages.js')
middleware['languages'] = middleware['languages'].default || middleware['languages']

middleware['noAuth'] = require('../middleware/noAuth.js')
middleware['noAuth'] = middleware['noAuth'].default || middleware['noAuth']

middleware['portalPermissions'] = require('../middleware/portalPermissions.js')
middleware['portalPermissions'] = middleware['portalPermissions'].default || middleware['portalPermissions']

middleware['removeRoutes'] = require('../middleware/removeRoutes.js')
middleware['removeRoutes'] = middleware['removeRoutes'].default || middleware['removeRoutes']

export default middleware
